import { types } from './types'
import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { addAlert } from '../../store/layout/actions'

const SET_CUSTOM_POOLS_LIST = `
  query customPools($page: Int, $size: Int, $orderBy: String, $sequence: String) {
    customPools(page: $page, size: $size, orderBy: $orderBy, sequence: $sequence) {
      items {
        id
        title
        sslPort
        stratumPort
        altStratumPort
        type
        coins
        soloPort
        poolServers{
          id
          country
          address
          type
          coin
        }
      }
      total
    }
  }
`

const CREATE_CUSTOM_POOL = `
  mutation createPool ($title: String, $sslPort: String, $stratumPort: String, $altStratumPort: String, $type: String, $coins: String, $soloPort: String, $poolServers: [PoolServerInput]) {
    createPool(title: $title, sslPort: $sslPort, stratumPort: $stratumPort, altStratumPort: $altStratumPort, type: $type, coins: $coins, soloPort: $soloPort, poolServers: $poolServers) {
      id
      title
      sslPort
      stratumPort
      altStratumPort
      type
      coins
      soloPort
      poolServers{
        id
        country
        address
        type
        coin
      }
    }
  }
`

const UPDATE_CUSTOM_POOL = `
  mutation updatePool ($id: ID!, $title: String, $sslPort: String, $stratumPort: String, $altStratumPort: String, $type: String, $coins: String, $soloPort: String, $poolServers: [PoolServerInput], $updateRigs: String) {
    updatePool(id: $id, title: $title, sslPort: $sslPort, stratumPort: $stratumPort, altStratumPort: $altStratumPort, type: $type, coins: $coins, soloPort: $soloPort, poolServers: $poolServers, updateRigs: $updateRigs) {
      id
      title
      sslPort
      stratumPort
      altStratumPort
      type
      coins
      soloPort
      poolServers{
        id
        country
        address
        type
        coin
      }
    }
  }
`
const DELETE_CUSTOM_POOL = `
  mutation deletePool ($id: ID!) {
    deletePool(id: $id)
  }
`

export const setPoolsList =
  ({ page, size, orderBy, sequence, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_CUSTOM_POOLS_LIST,
        variables: { page, size, orderBy, sequence, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { customPools } = data
      if (!customPools) {
        throw new Error("Can't get pools!")
      }

      dispatch({
        type: types.SET_CUSTOM_POOLS_LIST,
        data: customPools,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const createPool = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_CUSTOM_POOL,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createPool } = data
    if (!createPool) {
      throw new Error("Can't create pool!")
    }

    dispatch({
      type: types.CREATE_CUSTOM_POOL,
      data: createPool,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('Mining pool has been created successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updatePool = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_CUSTOM_POOL,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updatePool } = data
    if (!updatePool) {
      throw new Error("Can't update pool!")
    }

    dispatch({
      type: types.UPDATE_CUSTOM_POOL,
      data: updatePool,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('Mining pool has been updated successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deletePool = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_CUSTOM_POOL,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deletePool } = data
    if (!deletePool) {
      throw new Error("Can't remove mining pool!")
    }

    dispatch({
      type: types.DELETE_CUSTOM_POOL,
      data: formData.id,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('Mining pool has been removed successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}
