import { types } from './types'
import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { addAlert } from '../../store/layout/actions'
// import { errorNotification } from '../../utils/notificationHelper'

const CREATE_OVERCLOCKING_PROFILE = `
  mutation createOverclockingPreset ($rigId: ID, $name: String, $type: String, $cardType: String, $gpuName: String, $powerLimit: String, $gpuClock: String, $memClock: String, $coreVddc: String, $fan: String, $action: String, $mdpm: String, $dpm: String, $memVddc: String, $amdmemorytweak: String, $overclockingAlgo: String, $onGodAnEthArgPill: String, $autoTune: String, $profileType: String, $slotId: Int,$p0:Boolean, $overclockingCards: [OverclockingCardsInputType]) {
    createOverclockingPreset(rigId: $rigId, name: $name, type: $type, cardType: $cardType, gpuName: $gpuName, powerLimit: $powerLimit, gpuClock: $gpuClock, memClock: $memClock, coreVddc: $coreVddc, fan: $fan, action: $action, mdpm: $mdpm, dpm: $dpm, memVddc: $memVddc, amdmemorytweak: $amdmemorytweak, overclockingAlgo: $overclockingAlgo, onGodAnEthArgPill: $onGodAnEthArgPill, autoTune: $autoTune, profileType: $profileType, slotId: $slotId, p0:$p0, overclockingCards: $overclockingCards) {
      id
    }
  }
`

const UPDATE_OVERCLOCKING_PROFILE = `
  mutation updateOverclockingPreset ($id: ID,$rigId: ID, $name: String, $type: String, $cardType: String, $gpuName: String, $powerLimit: String, $gpuClock: String, $memClock: String, $coreVddc: String, $fan: String, $action: String, $mdpm: String, $dpm: String, $memVddc: String, $amdmemorytweak: String, $overclockingAlgo: String, $onGodAnEthArgPill: String, $autoTune: String, $profileType: String, $slotId: Int,$p0:Boolean, $overclockingCards: [OverclockingCardsInputType]) {
    updateOverclockingPreset(id: $id, rigId: $rigId, name: $name, type: $type, cardType: $cardType, gpuName: $gpuName, powerLimit: $powerLimit, gpuClock: $gpuClock, memClock: $memClock, coreVddc: $coreVddc, fan: $fan, action: $action, mdpm: $mdpm, dpm: $dpm, memVddc: $memVddc, amdmemorytweak: $amdmemorytweak, overclockingAlgo: $overclockingAlgo, onGodAnEthArgPill: $onGodAnEthArgPill, autoTune: $autoTune, profileType: $profileType, slotId: $slotId, p0:$p0, overclockingCards: $overclockingCards) {
      id
        rigId
        type
        profileType
        slotId
        name
        cardType
        gpuName
        powerLimit
        gpuClock
        memClock
        coreVddc
        fan
        action
        dpm
        mdpm
        memVddc
        amdmemorytweak
        overclockingAlgo
        onGodAnEthArgPill
        autoTune
        p0
        
        overclockingCards{
            id
            cardId
            overclockingPresetId
            cardNum
            cardNumo
            gpuName
            cardSlot
            cardType
            powerLimit
            gpuClock
            memClock
            coreVddc
            fan
            action
            dpm
            mdpm
            mvddc
            memVddc
            vendorName
            memInfo
            memType
            fwInfo
            vram
        }
    }
  }
`

const APPLY_OVERCLOCKING_PROFILE = `
  mutation setOverclockingPreset ($rigId: Int!, $id: ID!, ) {
    setOverclockingPreset(rigId: $rigId, id: $id) {
        id
    }
  }
`

const SET_SLOTS_DATA = `
  query OverclockingSlotList($page: Int, $size: Int) {
    OverclockingSlotList(page: $page, size: $size) {
      items {
        id
        userId
        name
        algo
        dualAlgo
        coin
        dualCoin
        comment
        rigsNumber
      }
      total
    }
  }
`

const CREATE_SLOT = `
  mutation createOverclockingSlot($name: String, $algo: String, $dualAlgo: String, $coin: String, $dualCoin: String, $comment: String) {
    createOverclockingSlot(name: $name, algo: $algo, dualAlgo: $dualAlgo, coin: $coin, dualCoin: $dualCoin, comment: $comment) {
      id
      userId
      name
      algo
      dualAlgo
      coin
      dualCoin
      comment
    }
  }
`

const UPDATE_SLOT = `
  mutation updateOverclockingSlot($id: Int, $name: String, $algo: String, $dualAlgo: String, $coin: String, $dualCoin: String, $comment: String) {
    updateOverclockingSlot(id: $id, name: $name, algo: $algo, dualAlgo: $dualAlgo, coin: $coin, dualCoin: $dualCoin, comment: $comment) {
      id
      userId
      name
      algo
      dualAlgo
      coin
      dualCoin
      comment
    }
  }
`

const DELETE_SLOT = `
  mutation deleteOverclockingSlot($id: Int!) {
    deleteOverclockingSlot(id: $id)
  }
`

const DELETE_OVERCLOCKING_PROFILE = `
  mutation deleteOverclockingPreset($id: ID!) {
    deleteOverclockingPreset(id: $id)
  }
`
const ASSIGN_SLOT = `
  mutation assignSlot($name: String, $rigIds: String!, $profileName: String!, $coin: String, $dualCoin: String, $comment: String, $algo: String, $dualAlgo: String, $slotId: Int) {
    assignSlot(name: $name, rigIds: $rigIds, profileName: $profileName, coin: $coin, dualCoin: $dualCoin, comment: $comment, algo: $algo, dualAlgo: $dualAlgo, slotId: $slotId)
  }
`

const SET_PROFILES_DATA = `
  query overclockingPresetList($page: Int, $size: Int, $filters: OverclockingPresetFilters!) {
    overclockingPresetList(page: $page, size: $size, filters: $filters) {
      items {
        id
        rigId
        type
        profileType
        slotId
        name
        cardType
        gpuName
        powerLimit
        gpuClock
        memClock
        coreVddc
        fan
        action
        dpm
        mdpm
        memVddc
        amdmemorytweak
        overclockingAlgo
        onGodAnEthArgPill
        autoTune
        socVDDmax
        p0
        memControlVoltage
        socFrequency
        overclockingCards{
            id
            cardId
            overclockingPresetId
            cardNum
            cardNumo
            gpuName
            cardSlot
            cardType
            powerLimit
            gpuClock
            memClock
            coreVddc
            fan
            action
            dpm
            mdpm
            mvddc
            memVddc
            vendorName
            memInfo
            memType
            fwInfo
            vram
            socVDDmax
        memControlVoltage
        socFrequency
        }
      }
      total
    }
  }
`

const SET_ALGOCOINS_DATA = `
  query algoCoinsList($page: Int, $size: Int) {
    algoCoinsList(page: $page, size: $size) {
      items {
        id
        algo
        coins
      }
    }
  }
`

export const setOverclockingProfilesList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_PROFILES_DATA,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }
      const { overclockingPresetList } = data
      if (!overclockingPresetList) {
        throw new Error("Can't get overclockingProfilesList")
      }

      dispatch({
        type: types.SET_PROFILES_DATA,
        data: overclockingPresetList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const setAlgoCoinsList =
  ({ page, size }) =>
  async dispatch => {
    try {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_ALGOCOINS_DATA,
        variables: { page, size },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }
      const { algoCoinsList } = data
      if (!algoCoinsList) {
        throw new Error("Can't get algoCoinsList")
      }

      dispatch({
        type: types.SET_ALGOCOINS_DATA,
        data: algoCoinsList,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
    }
  }

export const saveOverclockingProfile = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_OVERCLOCKING_PROFILE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { createOverclockingPreset } = data
    if (!createOverclockingPreset) {
      throw new Error("Can't overclocking card")
    }

    dispatch({
      type: types.CREATE_OVERCLOCKING_PROFILE,
      data: createOverclockingPreset,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Profile has been saved successfully!', 'success'))
    return createOverclockingPreset
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updateOverclockingProfile = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_OVERCLOCKING_PROFILE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    console.log(data)
    const { updateOverclockingPreset } = data
    if (!updateOverclockingPreset) {
      throw new Error("Can't overclocking card")
    }

    dispatch({
      type: types.UPDATE_OVERCLOCKING_PROFILE,
      data: updateOverclockingPreset,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Profile has been updated successfully!', 'success'))
    return updateOverclockingPreset
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deleteProfile = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_OVERCLOCKING_PROFILE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response Body Is Empty!')
    }

    console.log('delete', data)

    const { deleteOverclockingPreset } = data
    if (!deleteOverclockingPreset) {
      throw new Error("Can't Delete Profile!")
    }

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch({
      type: types.DELETE_PROFILE,
      data: formData,
    })

    dispatch(addAlert('Profile has been deleted successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const applyOverclockingProfile = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    console.log('formData: ', formData)

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: APPLY_OVERCLOCKING_PROFILE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { setOverclockingPreset } = data
    if (!setOverclockingPreset) {
      throw new Error("Can't overclocking card")
    }

    dispatch({
      type: types.APPLY_OVERCLOCKING_PROFILE,
      data: setOverclockingPreset,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Profile has been applied successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setSlotsList =
  ({ page, size }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_SLOTS_DATA,
        variables: { page, size },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }
      const { OverclockingSlotList } = data
      if (!OverclockingSlotList) {
        throw new Error("Can't get Slots")
      }

      dispatch({
        type: types.SET_SLOTS_DATA,
        data: OverclockingSlotList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const createSlot = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    console.log(formData)

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_SLOT,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { createOverclockingSlot } = data
    if (!createOverclockingSlot) {
      throw new Error("Can't overclocking card")
    }

    dispatch({
      type: types.CREATE_SLOT,
      data: createOverclockingSlot,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Slot has been created successfully!', 'success'))
    return createOverclockingSlot
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updateSlot = formData => async dispatch => {
  console.log(formData)
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_SLOT,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { updateOverclockingSlot } = data
    if (!updateOverclockingSlot) {
      throw new Error("Can't overclocking card")
    }

    dispatch({
      type: types.UPDATE_SLOT,
      data: updateOverclockingSlot,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Slot has been updated successfully!', 'success'))
    return updateOverclockingSlot
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deleteSlot = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_SLOT,
      variables: { id: parseInt(formData) },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response Body Is Empty!')
    }

    const { deleteOverclockingSlot } = data
    if (!deleteOverclockingSlot) {
      throw new Error("Can't delete slot!")
    }

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch({
      type: types.DELETE_SLOT,
      data: formData,
    })

    dispatch(addAlert('Slot has been deleted successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const assignSlot = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: ASSIGN_SLOT,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { assignSlot } = data
    if (!assignSlot) {
      throw new Error("Can't assign slot")
    }

    dispatch({
      type: types.ASSIGN_SLOT,
      data: formData,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Slot has been applied successfully!', 'success'))
    return assignSlot
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}
