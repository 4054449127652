import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { addAlert } from '../../store/layout/actions'

const signUpQuery = `
  mutation SignUp($email: String!, $password: String!, $inviteCode: String, $userName: String!, $captchaToken: String!) {
    signUp(email: $email, password: $password, inviteCode: $inviteCode, userName: $userName, captchaToken: $captchaToken)
  }
`

export const signUp =
  ({ userName, inviteCode, email, password, history, captchaToken }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: signUpQuery,
        variables: { userName, inviteCode, email, password, captchaToken },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      if (!data.signUp) {
        if (errors)
          errors.map(error => {
            dispatch(addAlert(error.message, 'danger'))
            return error
          })
        dispatch({
          type: 'SET_LOADING_STATE',
          active: false,
        })
        return false
      }

      dispatch(addAlert('You were signed up successfully!', 'success'))
      dispatch(addAlert('Please verify Your email', 'primary'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      setTimeout(() => {
        history.push(`${process.env.PUBLIC_URL}/login`)
      }, 2000)
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }
