import { types } from './types'
import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { addAlert } from '../../store/layout/actions'
// import { errorNotification } from '../../utils/notificationHelper'

const SET_WALLETS_LIST = `
  query walletsList($page: Int, $size: Int) {
    walletsList(page: $page, size: $size) {
      items {
        id
        userId
        name
        address
        coin
        source
      }
      total
    }
  }
`

const SET_COINS_LIST = `
  query coinList($page: Int, $size: Int) {
    coinList(page: $page, size: $size) {
      items {
        id
        nameSymbol
        units
      }
      total
    }
  }
`

const SET_POOLS_LIST = `
  query poolList($page: Int, $size: Int) {
    poolList(page: $page, size: $size) {
      items {
        id
        title
        sslPort
        stratumPort
        altStratumPort
        type
        coins
        soloPort
        poolServers{
          id
          country
          address
          type
          coin
        }
      }
      total
    }
  }
`

const SET_POOL = `
  query pool($poolId: Int) {
    pool(poolId: $poolId) {
      id
      userName
      password
      title
      sslPort
      stratumPort
      altStratumPort
      type
        coins
      poolServers{
        id
        country
        address
      }
    }
  }
`

const SET_MINERS_LIST = `
  query minerList($page: Int, $size: Int, $orderBy: String, $sequence: String) {
    minerList(page: $page, size: $size, orderBy: $orderBy, sequence: $sequence) {
      items {
        id
        name
        cardType
        minerVersions{
            id
            minerId
            version
            dualCoins
            params{
                id
                minerVersionId
                algo
                algoInMP
            }
        }
        minerParams{
          id
          paramName
          consts{
            id
            paramLabel
            paramValue
          }
        }
      }
      total
    }
  }
`

const CREATE_WALLET = `
  mutation createWallet ($name: String!, $address: String!, $coin: String!, $source: String!) {
    createWallet(name: $name, address: $address, coin: $coin, source: $source) {
      id
      name
      address
      coin
      source
      createdAt
      updatedAt
    }
  }
`

const DELETE_WALLET = `
  mutation deleteWallet ($walletID: ID!) {
    deleteWallet(walletID: $walletID)
  }
`
const UPDATE_WALLET = `
  mutation updateWallet ($id: ID!, $name: String!, $address: String!, $coin: String!, $source: String!, $applyAllRigs: Boolean! ) {
    updateWallet(id: $id, name: $name, address: $address, coin: $coin, source: $source, applyAllRigs: $applyAllRigs) {
      id
      name
      address
      coin
      source
      applyAllRigs
      createdAt
      updatedAt
    }
  }
`

const CREATE_MINING_PPESET = `
  mutation newMiningPreset ($name: String!, $flightSheets: [FlightSheetInput],$slotId: Int) {
    newMiningPreset(name: $name, flightSheets: $flightSheets, slotId: $slotId) {
      id
        name
        slotId
        flightSheets{
          id
          wallet
          coin
          pool
          dualWallet
          dualCoin
          dualPool
          miner
          number
          serverIds
          poolSsl
          dualServerIds
          dualPoolSsl
          userPool
          dualUserPool
          minerParams {
            id
            debug
            logs
            watchdog
            dualMode
            rotateLogs
            fork
            logsDays
            startMiningIn
            protocol
            version
            worker
            logsStorage
            miner_params_add
            algo
            dualAlgo
            password
          }
          minerObj{
            id
            name
            cardType
            minerParams{
              paramName
              consts{
                id
                paramLabel
                paramValue
              }
            }
          }
          walletObj{
            id
            userId
            name
            address
            coin
            source
          }
          dualWalletObj{
            id
            userId
            name
            address
            coin
            source
          }
          coinObj{
            id
            nameSymbol
          }
          dualCoinObj{
            id
            nameSymbol
          }
          poolObj{
            id
            title
            sslPort
            stratumPort
            altStratumPort
            poolServers{
              id
              country
              address
            }
          }
          dualPoolObj{
            id
            title
            sslPort
            stratumPort
            altStratumPort
            poolServers{
              id
              country
              address
            }
          }
        }
    }
  }
`

const UPDATE_MINING_PPESET = `
  mutation updateMiningPreset ($id: ID!, $name: String!, $flightSheets: [FlightSheetInput],$slotId: Int) {
    updateMiningPreset(id: $id, name: $name, flightSheets: $flightSheets, slotId: $slotId) {
      id
        name
        slotId
        flightSheets{
          id
          wallet
          coin
          pool
          dualWallet
          dualCoin
          dualPool
          miner
          number
          serverIds
          poolSsl
          dualServerIds
          dualPoolSsl
          userPool
          dualUserPool
          minerParams {
            id
            debug
            logs
            watchdog
            dualMode
            rotateLogs
            fork
            logsDays
            startMiningIn
            protocol
            version
            worker
            logsStorage
            miner_params_add
            algo
            dualAlgo
            password
          }
          minerObj{
            id
            name
            cardType
            minerParams{
              paramName
              consts{
                id
                paramLabel
                paramValue
              }
            }
          }
          walletObj{
            id
            userId
            name
            address
            coin
            source
          }
          dualWalletObj{
            id
            userId
            name
            address
            coin
            source
          }
          coinObj{
            id
            nameSymbol
          }
          dualCoinObj{
            id
            nameSymbol
          }
          poolObj{
            id
            title
            sslPort
            stratumPort
            altStratumPort
            poolServers{
              id
              country
              address
            }
          }
          dualPoolObj{
            id
            title
            sslPort
            stratumPort
            altStratumPort
            poolServers{
              id
              country
              address
            }
          }
        }
    }
  }
`

const DELETE_MINING_PPESET = `
  mutation deleteMiningPreset ($miningPresetId: ID!) {
    deleteMiningPreset(miningPresetId: $miningPresetId)
  }
`

const COPY_MINING_PPESET = `
  mutation copyMiningPreset ($mpId: ID!) {
    copyMiningPreset(mpId: $mpId){
      id
        name
        slotId
        flightSheets{
          id
          wallet
          coin
          pool
          dualWallet
          dualCoin
          dualPool
          miner
          number
          serverIds
          poolSsl
          dualServerIds
          dualPoolSsl
          userPool
          dualUserPool
          minerParams {
            id
            debug
            logs
            watchdog
            dualMode
            rotateLogs
            fork
            logsDays
            startMiningIn
            protocol
            version
            worker
            logsStorage
            miner_params_add
            algo
            dualAlgo
            password
          }
          minerObj{
            id
            name
            cardType
            minerParams{
              paramName
              consts{
                id
                paramLabel
                paramValue
              }
            }
          }
          walletObj{
            id
            userId
            name
            address
            coin
            source
          }
          dualWalletObj{
            id
            userId
            name
            address
            coin
            source
          }
          coinObj{
            id
            nameSymbol
          }
          dualCoinObj{
            id
            nameSymbol
          }
          poolObj{
            id
            title
            sslPort
            stratumPort
            altStratumPort
            poolServers{
              id
              country
              address
            }
          }
          dualPoolObj{
            id
            title
            sslPort
            stratumPort
            altStratumPort
            poolServers{
              id
              country
              address
            }
          }
        }
    }
  }
`

const SET_PRESETS_LIST = `
  query miningPresetList($page: Int, $size: Int, $filters: MPFiltersType) {
    miningPresetList(page: $page, size: $size, filters: $filters) {
      items {
        id
        name
        slotId
        slot {
          id
          name
        }
        flightSheets{
          id
          wallet
          coin
          pool
          dualWallet
          dualCoin
          dualPool
          miner
          number
          serverIds
          poolSsl
          dualServerIds
          dualPoolSsl
          userPool
          dualUserPool
          minerParams {
            id
            debug
            logs
            watchdog
            dualMode
            rotateLogs
            fork
            logsDays
            startMiningIn
            protocol
            version
            worker
            logsStorage
            miner_params_add
            algo
            dualAlgo
            password
          }
          minerObj{
            id
            name
            cardType
            minerParams{
              paramName
              consts{
                id
                paramLabel
                paramValue
              }
            }
          }
          walletObj{
            id
            userId
            name
            address
            coin
            source
          }
          dualWalletObj{
            id
            userId
            name
            address
            coin
            source
          }
          coinObj{
            id
            nameSymbol
          }
          dualCoinObj{
            id
            nameSymbol
          }
          poolObj{
            id
            title
            sslPort
            stratumPort
            altStratumPort
            poolServers{
              id
              country
              address
            }
          }
          dualPoolObj{
            id
            title
            sslPort
            stratumPort
            altStratumPort
            poolServers{
              id
              country
              address
            }
          }
        }
      }
      total
    }
  }
`

const SET_AVERAGE_HASH_LIST = `
  query averageHashList($page: Int, $size: Int) {
    averageHashList(page: $page, size: $size) {
      items {
        id
        cardName
        ethash
        zhash
        kawPow
        autoLykos
        cnheavy
        cryptoNightR
        aion
      }
      total
    }
  }
`

const RESTART_RIGS_AFTER_UPDATE_MP = `
  mutation updateRigsMP($mpID: Int) {
    updateRigsMP(mpID: $mpID)
  }
`

export const setAverageHashList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_AVERAGE_HASH_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { averageHashList } = data
      if (!averageHashList) {
        throw new Error("Can't get wallets")
      }
      dispatch({
        type: types.SET_AVERAGE_HASH_LIST,
        data: averageHashList,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      // dispatch(errorNotification(errorMessage))
    }
  }

export const setWalletsList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_WALLETS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { walletsList } = data
      if (!walletsList) {
        throw new Error("Can't get wallets")
      }
      dispatch({
        type: types.SET_WALLETS_LIST,
        data: walletsList,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      // dispatch(errorNotification(errorMessage))
    }
  }

export const setCoinsList =
  ({ page, size, orderBy, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_COINS_LIST,
        variables: { page, size, orderBy, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { coinList } = data
      if (!coinList) {
        throw new Error("Can't get coins")
      }

      dispatch({
        type: types.SET_COINS_LIST,
        data: coinList,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      // dispatch(errorNotification(errorMessage))
    }
  }

export const setPoolsList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_POOLS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { poolList } = data
      if (!poolList) {
        throw new Error("Can't get pools")
      }

      dispatch({
        type: types.SET_POOLS_LIST,
        data: poolList,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      // dispatch(errorNotification(errorMessage))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const setPool =
  ({ poolId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_POOL,
        variables: { poolId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { pool } = data
      if (!pool) {
        throw new Error("Can't get pool")
      }

      dispatch({
        type: types.SET_POOL,
        data: pool,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      // dispatch(errorNotification(errorMessage))
    }
  }

export const setMinersList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_MINERS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { minerList } = data
      if (!minerList) {
        throw new Error("Can't get pools")
      }

      dispatch({
        type: types.SET_MINERS_LIST,
        data: minerList,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      // dispatch(errorNotification(errorMessage))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const createWallet = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_WALLET,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createWallet } = data
    if (!createWallet) {
      throw new Error("Can't add wallet")
    }

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch({
      type: types.CREATE_WALLET,
      data: createWallet,
    })

    dispatch(addAlert('Wallet has been created successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deleteWallet = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_WALLET,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response Body Is Empty!')
    }

    const { deleteWallet } = data
    if (!deleteWallet) {
      throw new Error("Can't Delete Wallet!")
    }

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch({
      type: types.DELETE_WALLET,
      data: formData,
    })

    dispatch(addAlert('Wallet has been deleted successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updateWallet = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_WALLET,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateWallet } = data
    if (!updateWallet) {
      throw new Error("Can't add wallet")
    }

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch({
      type: types.UPDATE_WALLET,
      data: updateWallet,
    })

    dispatch(addAlert('Wallet Updated Successfully', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const createMiningPreset = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_MINING_PPESET,
      variables: {
        id: +formData.id,
        name: formData.name,
        slotId: parseInt(formData.slotId),
        flightSheets: formData.flightSheets,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    console.log(data)
    const { newMiningPreset } = data

    if (!newMiningPreset) {
      throw new Error("Can't add mining preset")
    }

    dispatch({
      type: types.CREATE_MINING_PRESET,
      data: newMiningPreset,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('Preset has been created successfully!', 'success'))

    return newMiningPreset
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updateMiningPreset = (formData, updateRigsStr) => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_MINING_PPESET,
      variables: {
        id: +formData.id,
        name: formData.name,
        slotId: +formData.slotId,
        flightSheets: formData.flightSheets,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateMiningPreset } = data

    if (!updateMiningPreset) {
      throw new Error("Can't update mining preset")
    }

    await dispatch({
      type: types.UPDATE_MINING_PRESET,
      data: updateMiningPreset,
    })

    if (updateRigsStr) {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: RESTART_RIGS_AFTER_UPDATE_MP,
        variables: { mpID: +formData.id },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateRigsMP } = data
      if (!updateRigsMP) {
        throw new Error("Can't set new mining preset to rigs!")
      }
    }

    dispatch(addAlert('Preset has been updated successfully!', 'success'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    return updateMiningPreset
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert(errorMessage, 'danger'))
  }
}

export const deleteMiningPreset = mpId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_MINING_PPESET,
      variables: { miningPresetId: mpId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deleteMiningPreset } = data

    if (!deleteMiningPreset) {
      throw new Error("Can't delete mining preset")
    }

    dispatch({
      type: types.DELETE_MINING_PRESET,
      data: mpId,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('Preset has been deleted successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const copyMiningPreset = mpId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    console.log(mpId)
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: COPY_MINING_PPESET,
      variables: { mpId: mpId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { copyMiningPreset } = data

    if (!copyMiningPreset) {
      throw new Error("Can't copy mining preset")
    }

    dispatch({
      type: types.COPY_MINING_PRESET,
      data: copyMiningPreset,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('Preset has been copied successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setMiningPresetList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_PRESETS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { miningPresetList } = data
      if (!miningPresetList) {
        throw new Error("Can't get pools")
      }

      dispatch({
        type: types.SET_PRESETS_LIST,
        data: miningPresetList,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const setMiningPresetListNoLoading =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_PRESETS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { miningPresetList } = data
      if (!miningPresetList) {
        throw new Error("Can't get pools")
      }
      //console.log(data)

      dispatch({
        type: types.SET_PRESETS_LIST,
        data: miningPresetList,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }
