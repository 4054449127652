import { types } from './types'

const initState = {
  schedulesList: [],
  scheduleTotal: 0,
  scheduleLogs: [],
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_SCHEDULE_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.schedulesList = data.items
      stateCopy.scheduleTotal = data.total
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_SCHEDULE: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.schedulesList = [...stateCopy.schedulesList, data]
      stateCopy.scheduleTotal = stateCopy.scheduleTotal + 1
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_SCHEDULE: {
      const { data } = action
      const stateCopy = { ...state }
      const scheduleListCopy = [...stateCopy.schedulesList]

      stateCopy.schedulesList = scheduleListCopy.map(sc => {
        if (+sc.id === +data.id) {
          sc = { ...data }
        }
        return sc
      })

      return {
        ...stateCopy,
      }
    }

    case types.DELETE_SCHEDULE: {
      const { data } = action
      const stateCopy = { ...state }
      const schedulesListCopy = [...stateCopy.schedulesList]
      stateCopy.schedulesList = schedulesListCopy.filter(schedule => +schedule.id !== data)
      stateCopy.scheduleTotal = stateCopy.scheduleTotal - 1
      return {
        ...stateCopy,
      }
    }
    case types.ACTION_SCHEDULE: {
      const { data } = action
      const stateCopy = { ...state }
      const schedulesListCopy = [...stateCopy.schedulesList]
      const index = schedulesListCopy.findIndex(schedule => +schedule.id === +data.scheduleId)
      schedulesListCopy[index] = {
        ...schedulesListCopy[index],
        action: data.action === 'stop' ? 'stop' : 'run',
      }
      stateCopy.schedulesList = schedulesListCopy

      return stateCopy
    }
    case types.LOGS_SCHEDULE: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.scheduleLogs = data.items
      return {
        ...stateCopy,
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
