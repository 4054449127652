import { types } from './types'
import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { addAlert } from '../../store/layout/actions'

const SET_SCHEDULE_LIST = `
  query schedulesList($page: Int, $size: Int) {
    schedulesList(page: $page, size: $size) {
      items {
        id
        firstPresetId
        secondPresetId
        name
        event
        activator
        action
      }
      total
    }
  }
`

const CREATE_SCHEDULE = `
  mutation createSchedule ($name: String!, $event: String!, $activator: String!, $action: String!, $firstPresetId: ID!, $secondPresetId: ID!) {
    createSchedule(name: $name, event: $event, activator: $activator, action: $action, firstPresetId: $firstPresetId, secondPresetId: $secondPresetId) {
      id
    }
  }
`

const UPDATE_SCHEDULE = `
  mutation updateSchedule ($scheduleId: ID!,$name: String!, $event: String!, $activator: String!, $firstPresetId: ID!, $secondPresetId: ID!) {
    updateSchedule(scheduleId:$scheduleId, name: $name, event: $event, activator: $activator, firstPresetId: $firstPresetId, secondPresetId: $secondPresetId) {
      id
        firstPresetId
        secondPresetId
        name
        event
        activator
        action
    }
  }
`
const DELETE_SCHEDULE = `
  mutation deleteSchedule ($scheduleId: ID!) {
    deleteSchedule(scheduleId: $scheduleId)
  }
`
const ACTION_SCHEDULE = `
  mutation actionSchedule ($scheduleId: ID!,$action: String!) {
    actionSchedule(scheduleId: $scheduleId,action: $action)
  }
`

const SCHEDULE_LOGS_LIST = `
query rigLogsList($page: Int, $size: Int, $filters: RigLogsFilters!) {
  rigLogsList(page: $page, size: $size, filters: $filters) {
    items {
      id
      workerId
      userId
      changeUserId
      cardUuid
      action
      description
      createdAt
      logsNum
      
    }
    total
  }
}
`

export const setScheduleList =
  ({ page, size }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_SCHEDULE_LIST,
        variables: { page, size },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { schedulesList } = data
      if (!schedulesList) {
        throw new Error("Can't get schedule")
      }

      dispatch({
        type: types.SET_SCHEDULE_LIST,
        data: schedulesList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const createSchedule = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_SCHEDULE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createSchedule } = data
    if (!createSchedule) {
      throw new Error("Can't Create Schedule")
    }

    dispatch({
      type: types.CREATE_SCHEDULE,
      data: { ...formData, id: createSchedule.id },
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Schedule has been created successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updateSchedule = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_SCHEDULE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { updateSchedule } = data
    if (!updateSchedule) {
      throw new Error('Schedule could not be updated!')
    }

    dispatch({
      type: types.UPDATE_SCHEDULE,
      data: updateSchedule,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('Schedule has been updated successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deleteSchedule = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_SCHEDULE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deleteSchedule } = data
    if (!deleteSchedule) {
      throw new Error('Schedule could not be updated!')
    }

    dispatch({
      type: types.DELETE_SCHEDULE,
      data: formData.scheduleId,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Schedule has been removed successfully!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const actionSchedule = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: ACTION_SCHEDULE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { actionSchedule } = data
    if (!actionSchedule) {
      throw new Error('Schedule could not be updated!')
    }

    dispatch({
      type: types.ACTION_SCHEDULE,
      data: formData,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(
      addAlert(
        `${
          formData.action === 'stop'
            ? 'Schedule has been stopped successfully!'
            : 'Schedule has been started successfully!'
        }`,
        'success',
      ),
    )
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const logsSchedule = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SCHEDULE_LOGS_LIST,
      variables: { page: 1, size: 1000, filters: { scheduleId: formData.id } },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { rigLogsList } = data
    if (!rigLogsList) {
      throw new Error("Can't get Schedule logs")
    }

    dispatch({
      type: types.LOGS_SCHEDULE,
      data: rigLogsList,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}
